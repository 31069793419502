import { Config } from ".";

const CONFIG: Config = {
  name: "Gigchasers Dashboard",
  description: "Gigchasers dashboard web application",
  version: "1.0",
  hostname:
    process.env.NODE_ENV === "development"
      ? // 'https://api-gigchasers.com/v1'
        "http://localhost:3001/v1"
      : "https://api-gigchasers.com/v1",
  Google: {
    mapKey: "AIzaSyB8xVAMRa2hoq8lRXEG1wXG3v2yR1KkHsQ",
  },
};

export default CONFIG;
