import firebase from "firebase/app";
// Required for side-effects
import "firebase/firestore";
import { GeoFirestore } from "geofirestore";

const firebaseConfigDev = {
  apiKey: "AIzaSyDjXx1AqIQnkyKkCPh8HZKZYyiKGfiLcbc",
  authDomain: "contracting-app.firebaseapp.com",
  databaseURL: "https://contracting-app.firebaseio.com",
  projectId: "contracting-app",
  storageBucket: "contracting-app.appspot.com",
  messagingSenderId: "557426956160",
  appId: "1:557426956160:web:c1393a9710ed0e40151365",
};

const firebaseConfig = {
  apiKey: "AIzaSyDSwnLY8H_oQq4plDE4ekeUVJw9C4rpNDY",
  authDomain: "gigchasers-realtime-events.firebaseapp.com",
  projectId: "gigchasers-realtime-events",
  storageBucket: "gigchasers-realtime-events.appspot.com",
  messagingSenderId: "546267210972",
  appId: "1:546267210972:web:beccb1dedf21ef3e72c019",
  measurementId: "G-3MNCS7GB61",
};

// Initialize Firebase
firebase.initializeApp(
  process.env.NODE_ENV === "development" ? firebaseConfigDev : firebaseConfig
);

export const firestore = firebase.firestore();
export const geoFirestore = new GeoFirestore(firestore);

firestore.enablePersistence({
  synchronizeTabs: true,
});
