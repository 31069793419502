import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import { useToasts } from 'react-toast-notifications';
import { Empty } from "../components/util";
import { APPLICATION_CONTEXT } from '../lib';
import { CurrencyFormatter, IInvoice, INVOICE_PAGE_LIMIT, Payment } from '../lib/payment';


/**
 * Profile component for viewing details of a user's profile. 
 */
export function Invoices() {
    const ctx = useContext(APPLICATION_CONTEXT)
    const [invoices, setInvoices] = useState<IInvoice[]>([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [loading, setLoading] = useState<boolean>(true)
    const { addToast } = useToasts()

    const fetchPayment = useCallback(async (pageToFetch = 1) => {
        try {
            setLoading(true)
            const paymentData = await Payment.getInvoices(ctx, pageToFetch, INVOICE_PAGE_LIMIT)
            setInvoices([...paymentData.invoices])
            setTotal(paymentData.total)
            setCurrent(pageToFetch)
        } catch (e) {
            addToast(e.message || 'Failed to fetch invoices', { appearance: 'error' })
            console.log(e, 'failed to fetch invoices')
        } finally {
            setLoading(false)
        }
    }, [invoices])

    useEffect(() => {
        fetchPayment(1)
    }, [ctx.user, ctx.user?.id])

    return (
        <div className='is-1 is-variable px-3 py-3 my-0 is-fullheight is-uppercase is-multiline'>
            <nav className='pagination py-3 px-5 m-0 is-rounded is-small' style={{
                width: '100%',
                borderRadius: '1em 1em 0 0',
                border: 'solid #dadada88 1px',
            }}>
                <button disabled={current === 1} className="pagination-previous has-background-white" title="Go to previous page">Previous</button>
                <button disabled={((current - 1 * INVOICE_PAGE_LIMIT) + invoices.length) <= total} className="pagination-next has-background-white" title="Go to next page" onClick={() => fetchPayment(current + 1)}>Next page</button>
                <div className='pagination-list py-1'>
                    <span className='is-flex-grow-1 has-text-left has-text-centered-mobile'>{`PAGE ${current} of ${Math.max(1, Math.ceil(total / INVOICE_PAGE_LIMIT))}`}</span>
                </div>
            </nav>
            {loading ?
                <div className='columns is-flex-centered is-gapless is-clipped' style={{
                    marginBottom: '0.8em',
                    borderRadius: '0 0 1em 1em',
                    backgroundColor: '#fafafa',
                    border: 'solid #dadada88 1px',
                    borderTop: 0,
                    height: '80%',
                }}>
                    <div className='column is-4 is-12-mobile'>
                        <div className='section px-6'>
                            <progress className="progress is-small is-danger" max="100">loading</progress>
                        </div>
                    </div>
                </div>
                :
                <div className='columns is-gapless' style={{
                    marginBottom: '0.8em',
                    borderRadius: '0 0 1em 1em',
                    backgroundColor: '#fafafa',
                    border: 'solid #dadada88 1px',
                    borderTop: 0,
                    minHeight: '80%',
                }}>
                    {invoices.length <= 0 && <Empty style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: 'transparent', width: '100%' }} text="No invoice available yet" />}

                    {invoices.length > 0 &&
                        <div className='table-container' style={{ width: '100%' }}>
                            <table className='table is-clipped is-bordered is-striped is-hoverable is-fullwidth' style={{
                                minWidth: '100%',
                                minHeight: '100%',
                                borderRadius: '0 0 1em 1em',
                                border: 'solid #dadada88 1px',
                            }}>
                                <thead>
                                    <tr className='is-size-7 py-2'>
                                        <th>Invoice ID</th>
                                        <th>Invoice Date</th>
                                        <th>Job title</th>
                                        <th>Amount</th>
                                        <th>Contractor Name</th>
                                        <th>Payment Method</th>
                                        <th>Invoice PDF</th>
                                    </tr>
                                </thead>
                                <tbody className='is-size-7 py-2'>
                                    {invoices.map(invoice => (
                                        <tr>
                                            <td>{invoice.invoiceReference}</td>
                                            <td>{moment(invoice.dateCreated).format('DD-MMM-YYYY')}</td>
                                            <td>{invoice.jobTitle}</td>
                                            <td>{CurrencyFormatter.format(parseInt(invoice.amount) / 100)}</td>
                                            <td>{invoice.deployee}</td>
                                            <td>{invoice.paymentMethod}</td>
                                            <td><a target="_blank" href={invoice.invoiceURL}>View PDF <FaDownload /></a></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            }
        </div>
    )
}