import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { NIL } from 'uuid';
import { ChatContext, MessageDetail, MessageList } from '../components/messages';
import { APPLICATION_CONTEXT } from '../lib';
import links from '../lib/links';
import { IChatItem, Message } from '../lib/message';

export function Messages()
{
    const ctx = useContext(APPLICATION_CONTEXT)
    const { chats } = useContext(ChatContext)
    const [chat, setChat] = useState<IChatItem | undefined>(undefined)
    const location = useLocation()

    const { id } = useParams()
    const history = useHistory()

    useEffect(() =>
    {
        if (id)
        {
            const chat = chats.find(chat => chat.id === id)
            if (chat)
            {
                setChat(chat)
            } else
            {
                // Check if chat object was passed in order to initialize a chat, the ID in this scenario should be "new"
                const { chat } = location.state as { chat: IChatItem } || { chat: null }
                if (chat)
                {
                    // TODO: always check database if the chat thread has already been created
                    setChat(chat)
                } else
                {
                    setChat(undefined)
                }
            }
        } else
        {
            setChat(undefined)
        }
    }, [location, chats])

    const isSupportUser = ctx.user?.phoneNumber === "+12345678910";

    return (
        <div className='columns is-gapless px-4 py-4 is-fullheight is-multiline'>
            <MessageList onNewChat={() =>
            {
                history.push(`${links.messages}/${Message.generateID(ctx.user?.id, ctx.user?.id)}`, {
                    chat: {
                        id: Message.generateID(ctx.user?.id, ctx.user?.id),
                        initialized: false,
                        recipient: ctx.user,
                        users: [ctx.user, ctx.user],
                    } as IChatItem
                })
            }} isSupportUser={isSupportUser} className='column is-3 is-12-mobile is-clipped is-fullheight' />
            <MessageDetail chat={chat} className='column is-7 is-12-mobile is-flex' />
        </div>
    )
}