import { unix } from 'moment';
import React, { useContext, useState } from 'react';
import { APPLICATION_CONTEXT } from '../lib';
import { SettingsDetail } from '../pages/settings';



export function LoginAndSecurity() {
    const ctx = useContext(APPLICATION_CONTEXT);
    const [email, setEmail] = useState(ctx.user?.email);
    const [editEmail, setEditEmail] = useState(false)
    const [state, setState] = useState({
        isLoadingEmail: false, lastPasswordChanged: ctx.user?.lastPasswordChanged ? unix(ctx.user?.lastPasswordChanged / 1000) : null
    });

    return (
        <SettingsDetail title={"Login and Security"} children={(
            <>
                <div className='columns'>
                    <div className='column has-text-left has-text-centered-mobile'>
                        <p className='title input is-size-5 pb-2 is-shadowless px-0' style={{ border: 0 }}>Email Address</p>
                        <p className='subtitle has-text-grey'>Email address connected to your account</p>
                    </div>
                    <div className='column has-text-right has-text-centered-mobile'>
                        <div className={`control`}>
                            <input className={`input ${editEmail ? '' : 'is-static'} has-text-black is-size-5 has-text-right has-text-centered-mobile`} value={email} contentEditable={false} disabled={!editEmail} onChange={e => setEmail(e.target.value)} />
                        </div>
                        <div className='field is-grouped is-grouped-right'>
                            {editEmail ? (
                                <div className='control'>
                                    <button className={`button ${state.isLoadingEmail ? 'is-loading' : ''}`} onClick={() => setEditEmail(false)}>Cancel</button>
                                </div>
                            ) : null}
                            <div className='control'>
                                <button className={`button ${editEmail ? 'is-success' : ''}`} onClick={() => {
                                    // TODO: process email change
                                    if (editEmail) {
                                        setEditEmail(false)
                                    } else {
                                        setEditEmail(true)
                                    }
                                }}>{editEmail ? 'Save' : 'Change'}</button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='columns'>
                    <div className='column has-text-left has-text-centered-mobile'>
                        <p className='title input is-size-5 pb-2 is-shadowless px-0' style={{ border: 0 }}>Phone Number</p>
                        <p className='subtitle has-text-grey'>Your phone number provided upon signing-up</p>
                    </div>
                    <div className='column has-text-right has-text-centered-mobile'>
                        <div className={`control`}>
                            <input className={`input is-static has-text-right has-text-black is-size-5 has-text-centered-mobile`} disabled value={ctx.user?.phoneNumber} />
                        </div>
                        <p className='subtitle has-text-grey'>Due to restrictions, contact support to modify your phone number</p>

                    </div>
                </div>
                {ctx.user?.role === 'admin' ?
                    <div className='columns'>
                        <div className='column has-text-left has-text-centered-mobile'>
                            <p className='title input is-size-5 pb-2 is-shadowless px-0' style={{ border: 0 }}>Password</p>
                            <p className='subtitle has-text-grey'>Maintain a strong, unique password to protect your account</p>
                        </div>
                        <div className='column has-text-right has-text-centered-mobile'>
                            <div className={`control title`}>
                                <button disabled className='button is-link' onClick={() => {
                                    // TODO:// implement password change
                                }}>Change</button>
                            </div>
                            <p className='subtitle has-text-grey'>{state.lastPasswordChanged ? `Last modified ${state.lastPasswordChanged.calendar({ sameElse: 'DD/MMM/YYYY' })}` : "Never Changed"}</p>
                        </div>
                    </div>
                    : null
                }
            </>
        )} />
    );
}
