import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { APPLICATION_CONTEXT } from '../lib';
import links from '../lib/links';
import { User } from '../lib/user';
import logo from '../logo_runner.jpg';
import { Empty } from './util';

export function VerifyList({ className }: { className?: string; })
{
    const ctx = useContext(APPLICATION_CONTEXT);
    const [users, setUsers] = useState<User[]>([])
    const [loading, setLoading] = useState(false);

    const { addToast } = useToasts();


    const fetchUsers = useCallback(async () =>
    {
        try
        {
            setLoading(true)
            const users = await User.getPendingUsers(ctx)
            setUsers([...users])
        } catch (e)
        {
            console.log(e, 'failed to fetch users')
            return addToast(e.message || 'Failed to fetch users!', { appearance: "error" });
        } finally
        {
            setLoading(false)
        }
    }, [users])

    useEffect(() =>
    {
        fetchUsers()
    }, [ctx.user, ctx.user?.id])

    return (
        <div className={className}>
            {loading ?
                <progress className="progress is-small is-info my-6" max="100">loading</progress>
                :
                <>
                    {users.length < 1 ?
                        <Empty style={{ background: 'transparent' }} text='No User Pending Verification!' icon={<FaUser className='has-text-warning-dark is-size-4 mb-2' />} />
                        : users.map(user => (
                            <div key={user.id} className={`column is-4-fullhd is-4-desktop is-12-touch list-item`}>
                                <VerifyListItem user={user} onRemove={() => setUsers(users.filter(u => u.id !== user.id))} />
                            </div>
                        ))}
                </>
            }
        </div>
    );
}

export function VerifyListItem({ user, onRemove }: { user: User, onRemove: Function })
{
    const ctx = useContext(APPLICATION_CONTEXT)
    const [loading, setLoading] = useState(false)
    const { addToast } = useToasts();

    const onVerifyClick = async () =>
    {
        setLoading(true)
        try
        {
            const verify = window.confirm("Verify user and grant access to the application for this user?")

            if (verify)
            {
                await User.verifyUser(ctx, user.id, { verify: true })
                addToast(`Successfully verified verification request for ${user.fullName}`, { appearance: 'success' })
                onRemove()
            }
        } catch (e)
        {
            console.log(e, 'failed to verify user')
            setLoading(false)
            return addToast(e.message || 'Failed to verify user!', { appearance: 'error' });
        }
    }

    const onRejectClick = async () =>
    {
        setLoading(true)
        try
        {
            const reject = window.confirm("Reject verification request for this user?")

            if (reject)
            {
                let message: string | null = null;
                message = window.prompt("Rejection reason", "Cannot verify identity")
                await User.verifyUser(ctx, user.id, { verify: false, message })
                addToast(`Rejected verification request for ${user.fullName}`, { appearance: 'success' })
                onRemove()
            }
        } catch (e)
        {
            console.log(e, 'failed to reject user verification')
            setLoading(false)
            return addToast(e.message || 'Failed to reject verification!', { appearance: 'error' });
        }
    }

    const time = moment(user.dateCreated)

    return (
        <div className='card'>
            <div className='card-content'>
                <div className='columns mb-0'>
                    <div className='column is-6-mobile has-text-weight-semibold is-flex-centered is-uppercase has-text-left'>
                        <p style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: "hidden" }}>{user.occupation}</p>
                    </div>
                    <div className='column has-text-centered-touch has-text-right'>
                        <div className='field has-addons has-addons-right'>
                            <div className='control'>
                                <button disabled={loading} onClick={onVerifyClick} className={`button is-uppercase is-success ${loading ? 'is-loading' : ''}`}>Verify</button>
                            </div>
                            <div className='control'>
                                <button disabled={loading} onClick={onRejectClick} className={`button is-uppercase is-danger ${loading ? 'is-loading' : ''}`}>Reject</button>
                            </div>
                        </div>
                    </div>

                </div>
                <figure className="image is-4by3">
                    <img src={user.photoID} alt={user.fullName} />
                </figure>
                <div className='columns'>
                    <div className='column has-text-left'>
                        <p>{user.state}</p>
                    </div>
                </div>
            </div>
            <div className='card-footer'>
                <div className='card-footer-item'>
                    <div className='columns px-4 is-vcentered is-mobile' style={{ flexDirection: 'column', flex: 1 }}>
                        <div className='column is-12'>
                            <Link to={{ pathname: `${links.profile}/${user.id}` }} className='columns is-vcentered is-mobile'>
                                <div className='column is-narrow is-flex' style={{ justifyContent: 'center' }}>
                                    <figure className='image is-flex is-32x32'>
                                        <img onError={(img) => img.currentTarget.src = logo} className='is-rounded has-background-grey' src={user.profilePhoto} alt={user.fullName} />
                                    </figure>
                                </div>
                                <div className='column is-narrow'>
                                    <div className='title is-6'>{user.fullName}</div>
                                </div>
                                <div className='column has-text-right has-text-black'>
                                    Joined {time.calendar({ sameElse: 'DD/MMM/YYYY' })}
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
