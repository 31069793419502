import React from 'react';
import { CardFragment } from "../components/util";
import { VerifyList } from '../components/verifyList';

export function Verification() {
    return (
        <div className='columns is-1 is-variable px-3 py-3 is-atleast-fullheight'>
            <div className='column is-12' style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                <CardFragment header='USERS PENDING VERIFICATION' optionsElement={<></>} style={{ marginBottom: '0.8em' }}>
                    <VerifyList className={'columns is-centered is-vcentered is-multiline is-1 is-variable'} />
                </CardFragment>
            </div>
        </div>
    )
}